import { Rsvp, FoodPreference } from "api/RsvpApi";

// TODO: Irgendwo zentral ablegen
export const TRANSLATIONS = {
    Going: 'Zusagen',
    NotGoing: 'Absagen',
    Fish: 'Fisch',
    Meat: 'Fleisch',
    Vegan: 'Vegan',
    Vegetarian: 'Vegetarisch',
} as Record<Rsvp | FoodPreference, string>;