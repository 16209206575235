import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material"
import usePageTitle from "../hooks/usePageTitle"
import useLocalStorage from "hooks/useLocalStorage";
import { useEffect, useState } from "react";
import axios from "axios";
import { PhotoChallengeResponse } from "api/PhotoChallengeApi";

export default function Selfies() {
    usePageTitle("Schnappschüsse");

    const [firstName, setFirstName, firstNameFromLocalStorage] = useLocalStorage('firstName', '');
    const [isFirstNameSet, setIsFirstNameSet] = useState(false);
    const [photoHints, setPhotoHints] = useState([] as string[]);

    useEffect(() => {
        if (firstNameFromLocalStorage) {
            setIsFirstNameSet(true);
        }
    }, [firstNameFromLocalStorage]);

    useEffect(() => {
        if (isFirstNameSet) {
            axios.get<PhotoChallengeResponse>(
                `/api/rsvp/photo_challenge?name=${firstName}`
            ).then(response => setPhotoHints(response.data.hints));
        }
    }, [firstName, isFirstNameSet]);

    return <Box sx={{
        paddingTop: '1rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '900px',
        display: 'flex',
        flexDirection: 'column'
    }}>
        {!isFirstNameSet && <>
            <Typography style={{ textAlign: 'center' }}>
                Bitte verrate uns kurz deinen Vornamen:
            </Typography>

            <Grid container spacing={2} mt={2}>
                <Grid item md={6} xs={12}>
                    <TextField autoFocus fullWidth
                        label="Vorname"
                        value={firstName}
                        onChange={e => setFirstName(e.currentTarget.value)}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={!firstName}
                        onClick={() => setIsFirstNameSet(true)}>
                        Weiter
                    </Button>
                </Grid>
            </Grid>
        </>}

        {isFirstNameSet && <>
            <Typography variant="h5">
                Willkommen, Secret Agent {firstName}!
            </Typography>

            <Typography>
                Deine Mission ist einfach: Identifiziere deine Zielperson und mache ein Bild mit ihm oder ihr!
            </Typography>

            <Box mt={2}>
                Mache ein Foto mit...

                <ul>
                    {photoHints.map(hint => <li key={hint}>{hint}</li>)}
                </ul>

                Macht gemeinsam ein Selfie (und <Link target="_blank" href="https://glacier.ilgen.dev/s/KxbdojRF7dkxLJ3">ladet es hier hoch</Link>) oder probiert zusammen die Fotobox aus!
                Vielleicht klebt ihr das Ergebnis ja auch in unser Gästebuch?
            </Box>

            <Box flexGrow={1} />

            <Typography mt={2} mb={1}>
                Alles erledigt? Lade die Seite neu für neue Missionen!
            </Typography>
        </>}

    </Box>
}