import React from 'react';
import { Link, LinkProps } from 'react-router-dom'


// eslint-disable-next-line react/display-name
export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(
  (props, ref) => {
    const { href, ...other } = props;
    return <Link ref={ref} to={href || '/'} {...other} />;
  }
);