import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    AppBar, Box, CssBaseline, Drawer, IconButton, Link, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { Close, Menu } from "@mui/icons-material";
import { LinkBehavior } from "../behaviors/LinkBehavior";
// import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import HomeIcon from '@mui/icons-material/Home'
import HotelIcon from '@mui/icons-material/Hotel'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { PageTitleContext } from "../hooks/usePageTitle";

export default function PageLayout() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState<string>('Corinnas und Henriks Hochzeit')

    useEffect(() => {
        document.title = `${pageTitle} - Corinnas und Henriks Hochzeit`;
    }, [pageTitle])

    const theme = createTheme({
        typography: {
            fontSize: 18,
        },
        palette: {
            mode: 'dark',
            primary: {
                main: 'rgb(201, 129, 84)',
            },
            secondary: {
                main: 'rgb(197, 116, 67)',
            },
            background: {
                default: '#354255',
                paper: '#314159',
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <PageTitleContext.Provider value={setPageTitle}>
                {/* <Paper sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}> */}
                <Drawer
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    sx={{
                        flexShrink: 0,
                    }}>
                    <Box sx={{ minWidth: 250, display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
                        <Box>
                            {/* Placeholder */}
                            <Toolbar>
                                <IconButton size="large" sx={{ mr: 2 }} />
                            </Toolbar>

                            <List>
                                <ListItemButton href="/info" component={LinkBehavior} onClick={() => setIsDrawerOpen(false)}>
                                    <ListItemIcon>
                                        <HomeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Wichtige Infos" />
                                </ListItemButton>
                                <ListItemButton href="/zollenspieker-faehrhaus" component={LinkBehavior} onClick={() => setIsDrawerOpen(false)}>
                                    <ListItemIcon>
                                        <HotelIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Übernachtung" />
                                </ListItemButton>
                                <ListItemButton href="/selfies" component={LinkBehavior} onClick={() => setIsDrawerOpen(false)}>
                                    <ListItemIcon>
                                        <CameraAltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Schnappschüsse" />
                                </ListItemButton>
                            </List>
                        </Box>
                    </Box>
                </Drawer>
                <Box component="nav" sx={{ display: 'flex' }}>
                    <AppBar position="sticky" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            <IconButton
                                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                                size="large"
                                edge="start"
                                sx={{ mr: 2 }}>
                                {isDrawerOpen ? <Close /> : <Menu />}
                            </IconButton>
                            <Typography variant="h6" component="div" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" sx={{ flexGrow: 1 }}>
                                <Link href="/" color="text.primary" sx={{textDecoration: 'none', }}>
                                    {pageTitle}
                                </Link>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box component="main" ml="1rem" mr="1rem" sx={{ display: 'flex', flexGrow: 1 }}>
                    <Outlet />
                </Box>
            </PageTitleContext.Provider>
        </ThemeProvider>
    )
}