import { FoodPreference } from "../api/RsvpApi";
import {
    Egg as VegetarianIcon,
    SetMeal as FishIcon,
    KebabDining as MeatIcon,
    SoupKitchen as VeganIcon,
    SvgIconComponent,
} from '@mui/icons-material';

export const FOOD_ICONS = {
    Fish: FishIcon,
    Meat: MeatIcon,
    Vegan: VeganIcon,
    Vegetarian: VegetarianIcon,
} as Record<FoodPreference, SvgIconComponent>;