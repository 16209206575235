import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css';

import PageLayout from './partials/PageLayout';
import Hotel from './pages/Hotel';
import Selfies from './pages/Selfies';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/de';
import Admin from './pages/Admin';
import Info from 'pages/Info';
// import Rsvp from 'pages/Rsvp';

function App() {
  // 🤢🤮
  moment.fn.toJSON = function () { return this.format('YYYY-MM-DD'); };

  const hasItHappenedYet = (Date.now() - new Date('2023-06-10T17:00:00.000+0200').getTime()) >= 0;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PageLayout />}>
            <Route index element={hasItHappenedYet ? <Selfies /> : <Info />} />
            <Route path="admin" element={<Admin />} />
            <Route path="info" element={<Info />} />
            {/* <Route path="rsvp" element={<Rsvp />} /> */}
            <Route path="zollenspieker-faehrhaus" element={<Hotel />} />
            <Route path="selfies" element={<Selfies />} />

            <Route path="*" element={<Info />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
