import { Box, Link, Typography } from "@mui/material"
import usePageTitle from "../hooks/usePageTitle"

export default function Hotel() {
    usePageTitle("Übernachtung im Zollenspieker Fährhaus");

    return <Box maxWidth="900px" sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
        <Typography variant="h5" color="primary" mt="1rem">
            Übernachtung im Zollenspieker Fährhaus
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Für die Übernachtung im Hotel der Location haben wir euch gesonderte Konditionen unter dem auf der
            Einladungskarte genannten Stichwort gesichert.
            Diese können <Typography color="primary" component="span"> bis zum 31.12.22 </Typography> abgerufen werden,
            danach gehen die Zimmer in den freien Verkauf zurück.
            Die oben genannten Zimmerpreise sind ohne Frühstück, zzgl. der Hamburger Kultur &
            Tourismustaxe, gelten pro Zimmer/Nacht und können ohne Verbindung zu dieser Veranstaltung
            nicht gebucht werden.
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Wir haben im Zollenspieker Fährhaus ein Zimmerkontingent reserviert, aus dem Ihr über das auf der 
            Einladungskarte vermerkte Stichwort Zimmer von Samstag auf Sonntag buchen könnt. 
            Einige Zimmer sind auch bereits ab Freitag verfügbar, wenn ihr früher anreisen möchtet!
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Tipp: Das Zollenspieker Fährhaus hat auch einen <span> </span>
            <Link href="https://www.zollenspieker-faehrhaus.de/hotel/erholung" color="#ffffff">SPA-Bereich</Link> 😏
        </Typography>

        <Typography variant="h6" color="primary" mt="1rem">
            Adresse
        </Typography>

        <Typography>
            <Link href="https://www.zollenspieker-faehrhaus.de/" color="#ffffff">Zollenspieker Fährhaus</Link><br />
            Zollenspieker Hauptdeich 141<br />
            21037 Hamburg
        </Typography>

        <Typography variant="h6" color="primary" mt="1rem">
            Kosten
        </Typography>

        <ul style={{ marginTop: 0 }}>
            <li>134,00 € bei Einzelbelegung</li>
            <li>149,00 € bei Doppelbelegung</li>
            <li>
                Aufbettung:
                <ul>
                    <li>Kinder im Bett der Eltern: kostenfrei</li>
                    <li>Baby- und Zustellbetten für Kinder von 0 bis 10 Jahre: 15,00 € pro Bett/Tag</li>
                </ul>
            </li>
            <li>Frühstücksbuffet (07:00 - 12:00)
                <ul>
                    <li>21,50 € pro Person (Hotelgast)</li>
                    <li>26,50 € pro Person (externer Gast)</li>
                    <li>Kostenfrei für Kinder von 0 - 4 Jahren</li>
                    <li>50% Ermäßigung für Kinder von 5 - 10 Jahren</li>
                </ul>
            </li>
        </ul>
    </Box>
}