import { Box, Link, Typography } from "@mui/material";
import usePageTitle from "hooks/usePageTitle";

export default function Info() {
    usePageTitle("Ankunft und Allgemeines");

    return <Box maxWidth="1280px" sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
        <Typography variant="h5" color="primary" mt="1rem">
            Wissenswertes rund um unsere Hochzeit
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Bald schon dürfen wir mit Euch allen unsere Hochzeit feiern! Damit Ihr bestmöglich darauf vorbereitet seid,
            möchten wir an dieser Stelle alle Infos sammeln, die Ihr am großen Tag brauchen könntet.
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Anreise und Einchecken
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Die Adresse des Zollenspieker Fährhaus lautet:
        </Typography>

        <Typography mt="0.5rem">
            <Link href="https://www.zollenspieker-faehrhaus.de/">Zollenspieker Fährhaus</Link>,
            Zollenspieker Hauptdeich 141,
            21037 Hamburg
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Es gibt zwei große Parkplätze, sodass hier eigentlich keine große Not aufkommen dürfte: <Link
                target="_blank" href="https://www.google.de/maps/place/Parkplatz+Hotel/@53.3994923,10.1854568,19z/data=!4m6!3m5!1s0x47b1edd63b42dc31:0xf9bbdf4c174043f8!8m2!3d53.3997704!4d10.186312!16s%2Fg%2F11qlygdzjb?hl=de&entry=ttu"
            >
                den Hotel-Parkplatz
            </Link> und <Link
                target="_blank" href="https://www.google.de/maps/place/Parkplatz+Hinterm+Deich/@53.3982463,10.1826634,219m/data=!3m1!1e3!4m17!1m10!3m9!1s0x47b1ec878df26611:0xce30d2e4a466d8a2!2sZollenspieker+F%C3%A4hrhaus+Hamburg!5m2!4m1!1i2!8m2!3d53.3991352!4d10.1856783!16s%2Fg%2F1ynnq2l8_!3m5!1s0x47b1ed2f1d28a919:0x864edd3493cfc5a8!8m2!3d53.3979908!4d10.181887!16s%2Fg%2F11f64blctj?hl=de&entry=ttu"
            >
                den Parkplatz hinter'm Deich
            </Link>.

            Eure im Zollenspieker Fährhaus gebuchten Zimmer stehen euch <em>ab spätestens 15 Uhr</em> zur Verfügung,
            sodass ihr dann noch etwa anderthalb Stunden Zeit habt, euch fertig zu machen - ab 16:30 Uhr versammeln wir
            uns langsam zur Trauung auf der Terrasse des Traditionshauses, die Trauung beginnt um 17:00 Uhr.
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Die Zeit bis zur Trauung
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Auch wir werden vorab bereits im Zollenspieker Fährhaus unterwegs sein, ab etwa 15:00 Uhr machen wir unsere Brautpaarfotos!
            Bitte habt Verständnis dafür, dass wir diese Zeit ausführlich für möglichst viele Bilder nutzen wollen - so
            lieb wir Euch auch alle haben, für Begrüßungen und Smalltalk werden wir hier eher keine Zeit haben. Solltet ihr bereits
            früher da sein, möchten wir Euch ermutigen, die verbleibende Zeit zu nutzen, um die anderen Gäste kennenzulernen.
            Wir freuen uns sehr darauf, Euch alle zu sehen und mit Euch zu feiern!
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Die Trauung
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Die Trauung beginnt um 17:00 auf der Terrasse des <b><em>Traditionshauses</em></b> des Zollenspieker Fährhaus -
            vom Haupthaus aus (wo die Rezeption ist) könnt ihr dafür am einfachsten zum Haupteingang raus, euch dann nach links
            wenden - und so lang laufen, bis ihr den Eingang zum Garten seht. Im Traditionshaus stehen Euch auch für den
            gesamten Nachmittag und Abend Garderoben und WCs zur Verfügung.
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Nach der Trauung: Sektempfang und Fotos
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Direkt im Anschluss an die Trauung werden wir zunächst aus dem Garten ausziehen - aber keine Angst, wir
            kommen wieder! Nach unserem Auszug wollen wir gern zunächst ein Gruppenbild mit euch machen; dafür versammelt Ihr Euch
            nach unserem Auszug an der Treppe am Ende des Gartens Richtung Elbe hin. Unsere Fotografin Franzi wird euch hier dirigieren.
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Direkt nach diesem "großen" Gruppenfoto <b><em>
                gehen wir wieder mit Euch hinauf zur Terrasse für weitere Bilder.
                Bitte verzichtet in diesem Moment noch darauf, zu uns zum Anleger herunterzukommen, um zu gratulieren
            </em></b> - dafür wird beim Sektempfang noch genug Zeit bleiben. Oben möchten wir dann mit Euch auf unsere
            Hochzeit anstoßen und weitere Bilder mit Euch machen - dafür wird Euch Franzi in Gruppen zusammenrufen.
            Nach dieser Session haben wir hoffentlich noch Zeit für Fotos mit Einzelnen von Euch.
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Franzi wird uns übrigens auch den ganzen Abend über noch begleiten - und wir wünschen uns, dass ihr die
            Gelegenheit ausgiebig nutzt, um mit ihr Fotos zu machen! Macht Fotos von Euch allein, mit uns, mit Euren Freunden -
            oder auch mit (noch) fremden Leuten! Traut Euch vor die Linse, damit wir alle viele schöne Erinnerungen
            von Euch diesen Tag haben!
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Im Festsaal
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Der Festsaal kann während des Sektempfangs bereits genutzt werden. Gegen 19:30 werden wir uns alle im Saal einfinden.
            Eure Präsente und Glückwünsche finden dort ihren Platz hinter der Fotobox. Neben der Fotobox gibt es ein
            Gästebuch und Spiele, die für eure Abendvergnügung bereit liegen, wenn das Tanzbein mal eine Pause braucht.
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Die meisten von Euch wissen vermutlich, dass Corinnas Eltern gehörlos sind - damit sie und Ihr trotzdem in den
            Genuss kommt, miteinander zu sprechen, werden wir den Abend über zwei Dolmetscher:innen zugegen haben. Wir
            würden uns sehr freuen, wenn Ihr nicht schüchtern seid und die Chance nutzt, Euch auch mit den beiden zu unterhalten
            - wann hat man schon mal die Chance, so direkt die Kommunikationsbarriere hinter sich zu lassen?
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Neben Corinnas Eltern haben wir noch mehr Gäste, deren Muttersprache nicht Deutsch ist - wir haben Gäste
            aus den Niederlanden, aus Frankreich und aus Italien. Sprecht sie gern auf Deutsch oder auf Englisch an -
            wir haben ausschließlich tolle Leute eingeladen und freuen uns, wenn Ihr alle miteinander ins Gespräch kommt!
        </Typography>

        <Typography variant="h5" color="primary" mt="1rem">
            Der Morgen danach
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            Einige Gäste übernachten, wie wir, im Zollenspieker Fährhaus. Wir haben kein gemeinsames Frühstück mit allen
            geplant, streben aber selbst an gegen 10 Uhr zu frühstücken (Frühstück geht bis 12 Uhr, Ausschecken bis 11 Uhr).
            Wir freuen uns, einige von Euch beim Frühstück wieder zu sehen.
        </Typography>

        <Typography variant="h5" color="primary" mt="2rem">
            Das wünschen wir uns von Euch
        </Typography>


        <Typography mt="0.5rem" textAlign="justify">
            <ul>
                <li>Eure Glückwünsche kommen unserer nächsten Japanreise sowie der Austattung unserer gemeinsamen Wohnung zu Gute.</li>
                <li>Kommt, wie Ihr Euch wohlfühlt - weißes Schuhwerk oder Kleiderdetails sind kein Problem, bitte macht euch so schick wie ihr mögt.</li>
                <li>Wir möchten einfach einen schönen Abend mit euch verbringen.</li>
                <li>Traut euch wirklich gerne vor die Linse und auf die Tanzfläche!</li>
                <li>Redet miteinander - trefft Corinnas Eltern und unsere Gäste aus Frankreich, den Niederlanden und Italien.</li>
                <li>Schwingt das Tanzbein! Packt den Discofox-Grundschritt aus oder rastet aus und bildet einen Moshpit - alles ist erlaubt!</li>
            </ul>
        </Typography>

        <Typography variant="h4" color="primary" mt="2rem">
            Wichtige Hinweise - Das "Kleingedruckte"
        </Typography>

        <Typography mt="0.5rem" textAlign="justify">
            <ul>
                <li>keine Wunderkerzen/Feuerwerk etc. im Saal</li>
                <li>keine echten Blüten in den Veranstaltungsräumen und den Hotelzimmern (zum Streuen auf dem Boden)</li>
                <li>Ihr dürft dem DJ eure Wünsche nennen, er versucht sie dann einzubauen. Dies kann ein paar Lieder dauern</li>
            </ul>
        </Typography>

        <Typography mt="0.5rem" mb="3rem" textAlign="justify">
            Ihr habt noch Fragen, die hier nicht beantwortet wurden? Wendet euch an unsere Trauzeug:innen Belinda, Bianca
            und Dario! Ihre Kontaktdaten findet Ihr auf den Einladungen.
        </Typography>
    </Box>
}