import { Card, Box, Avatar, Typography, Icon, Divider, TextField, List, ListItem, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { PhotoChallengeHint } from "../api/PhotoChallengeApi";
import { FoodPreference, Person, RsvpReply } from "../api/RsvpApi";
import { FOOD_ICONS } from 'mappings/iconMappings';
import { TRANSLATIONS } from "mappings/translations";
import axios from "axios";
import React, { useCallback, useState } from "react";

export type GuestCardProps = {
    apiKey: string,
    reply: RsvpReply,
    person: Person,
    photoHints: PhotoChallengeHint[],
    onHintPosted: () => void,
};

async function postPhotoHint(
    apiKey: string,
    personId: number,
    hintText: string,
) {
    const response = await axios.post('/api/rsvp/admin/photo-challenge/hints', {
        personId, hintText,
    } as PhotoChallengeHint, {
        headers: { 'X-Api-Key': apiKey, }
    });

    if (response.status >= 400) {
        throw new Error('Unexpected status code: ' + response.status + ', message: ' + response.data);
    }
}

async function deletePhotoHint(
    apiKey: string,
    hintId: number,
) {
    const response = await axios.delete(`/api/rsvp/admin/photo-challenge/hints/${hintId}`, {
        headers: { 'X-Api-Key': apiKey, }
    });
    if (response.status >= 400) {
        throw new Error('Unexpected status code: ' + response.status + ', message: ' + response.data);
    }
}

const GuestCard: React.FC<GuestCardProps> = ({ apiKey, reply, person, photoHints, onHintPosted }) => {
    const [hintText, setHintText] = useState('');

    const onSubmitHint = useCallback(async (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            await postPhotoHint(apiKey, person.id!, hintText);
            setHintText('');
            onHintPosted();
        }
    }, [apiKey, person, hintText, onHintPosted]);

    const onDeleteHint = useCallback(async (hintId: number) => {
        await deletePhotoHint(apiKey, hintId);
        onHintPosted();
    }, [apiKey, onHintPosted])

    return <Card className={person.isDeleted ? 'person-card--deleted' : ''} sx={{
        padding: "1rem",
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Box display="flex" mb="0.5rem">
            <Avatar variant="rounded" sx={{ width: '3rem', height: '2rem' }} >
                {person.firstName.substring(0, 1)}
                {person.lastName.substring(0, 1)}
            </Avatar>
            <Typography color="secondary" ml="0.5rem">
                {person.firstName} {person.lastName}
            </Typography>
        </Box>

        <Typography variant="body2" sx={{ verticalAlign: 'center' }}>
            <Icon component={FOOD_ICONS[person.foodPreference]}
                sx={{ fontSize: '1rem', mr: '0.25rem' }} />
            {TRANSLATIONS[person.foodPreference as FoodPreference]}
        </Typography>

        {person.allergies && <Typography variant="body2">
            Allergien: {person.allergies || 'Keine'}
        </Typography>}

        <Typography variant="body2">
            {!!reply.arrival
                ? <>Ankunft: {reply.arrival}</>
                : <>&nbsp;</>}
        </Typography>

        <Divider sx={{ m: '0.25rem' }} />
        <Typography variant="body2" >
            Mache ein Foto mit...
        </Typography>
        <List dense>
            {photoHints?.map(hint =>
                <ListItem key={hint.id}
                    sx={theme => ({ fontSize: theme.typography.body2.fontSize })}
                    secondaryAction={
                        <IconButton
                            edge="end"
                            onClick={() => onDeleteHint(hint.id!)}
                            aria-label="delete"
                        >
                            <DeleteIcon />
                        </IconButton>
                    }
                >
                    ... {hint.hintText}
                </ListItem>
            )}
        </List>

        <TextField
            fullWidth
            size="small"
            label="Hinweis eingeben"
            value={hintText}
            onChange={e => setHintText(e.currentTarget.value)}
            onKeyDown={e => onSubmitHint(e)}
            sx={{ marginTop: 'auto' }}
        />
    </Card>;
};

export default GuestCard;