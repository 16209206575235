import React, { useContext, useEffect } from "react";


export const PageTitleContext = React.createContext<(v: string) => void>(
    () => {
        throw new Error('Forgot to wrap component in `PageLayout`');
    }
)

export default function usePageTitle(title: string): void {
    const setPageTitle = useContext(PageTitleContext);
    useEffect(() => setPageTitle(title))
}