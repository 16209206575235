import { Box, Grid, Icon, Skeleton, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useMemo } from "react";
import { useCallback, useEffect, useState } from "react";
import { FoodPreference, Guest, Rsvp, RsvpReply } from "../api/RsvpApi";
import useLocalStorage from "../hooks/useLocalStorage";
import './Admin.scss';
import { PhotoChallengeHint } from "../api/PhotoChallengeApi";
import GuestCard from "../components/GuestCard";
import { TRANSLATIONS } from "mappings/translations";
import { FOOD_ICONS } from "mappings/iconMappings";

export default function Admin() {
    const [apiKey, setApiKey] = useLocalStorage('apiKey', '');
    const [loadingRequests, setLoadingRequests] = useState(0);
    const [rsvpReplies, setRsvpReplies] = useState<RsvpReply[] | undefined>();
    const [photoChallengeHints, setPhotoChallengeHints] = useState<PhotoChallengeHint[] | undefined>();
    const [guestList, setGuestList] = useState<Guest[] | undefined>();

    const getRsvp = useCallback(async () => {
        setLoadingRequests(lr => lr + 1);

        const response = await axios.get<RsvpReply[]>(
            "/api/rsvp/admin/rsvp", {
            headers: { "X-Api-Key": apiKey },
        });

        setRsvpReplies(response.data);
        setLoadingRequests(lr => lr - 1);
    }, [apiKey, setLoadingRequests, setRsvpReplies]);

    const getPhotoChallengeHints = useCallback(async () => {
        setLoadingRequests(lr => lr + 1);

        const response = await axios.get<PhotoChallengeHint[]>(
            "/api/rsvp/admin/photo-challenge/hints", {
            headers: { "X-Api-Key": apiKey },
        });

        setPhotoChallengeHints(response.data);
        setLoadingRequests(lr => lr - 1);
    }, [apiKey, setLoadingRequests, setPhotoChallengeHints])

    const getGuestList = useCallback(async () => {
        setLoadingRequests(lr => lr + 1);

        const response = await axios.get<Guest[]>(
            "/api/rsvp/admin/guest-list", {
            headers: { "X-Api-Key": apiKey }
        });

        setGuestList(response.data);
        setLoadingRequests(lr => lr - 1);
    }, [apiKey, setLoadingRequests, setGuestList]);

    useEffect(() => {
        if (apiKey !== '') {
            getRsvp();
            getGuestList();
            getPhotoChallengeHints();
        }
    }, [apiKey, getRsvp, getGuestList, getPhotoChallengeHints]);

    const repliesByRsvp = useMemo(
        () => rsvpReplies?.reduce(
            (accu, reply) => {
                const group = (accu[reply.rsvp] || []);
                group.push(reply);
                accu[reply.rsvp] = group;

                return accu;
            },
            {} as Record<Rsvp, RsvpReply[]>
        ) ?? {} as Record<Rsvp, RsvpReply[]>,
        [rsvpReplies]);

    const photoHintsByPersonId = useMemo(
        () => photoChallengeHints?.reduce(
            (accu, hint) => {
                const group = accu[hint.personId] || [];
                group.push(hint);
                accu[hint.personId] = group;

                return accu;
            },
            {} as Record<number, PhotoChallengeHint[]>
        ) ?? {},
        [photoChallengeHints])

    const missingReplies = guestList?.filter(g => !g.hasReplied);

    const foodPreferences = rsvpReplies?.reduce(
        (accu, reply) => {
            for (const person of reply.people.filter(p => !p.isDeleted)) {
                accu[person.foodPreference]++;
            }

            return accu;
        },
        {
            Fish: 0,
            Meat: 0,
            Vegan: 0,
            Vegetarian: 0,
        } as Record<FoodPreference, number>
    );

    return <Box
        flexGrow={1}
        maxWidth="1280px"
        sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem'
        }}
    >
        <Box display="flex" flexDirection="row" alignContent="stretch">
            <TextField
                autoFocus
                fullWidth
                type="password"
                size="small"
                label="API-Key"
                value={apiKey}
                onChange={e => setApiKey(e.currentTarget.value)} />

        </Box>

        {loadingRequests > 0 && !rsvpReplies?.length && <>
            <Skeleton variant="rounded" height='40px' sx={{ mt: '0.5rem' }} />
            <Skeleton variant="rounded" height='40px' sx={{ mt: '0.5rem' }} />
            <Skeleton variant="rounded" height='40px' sx={{ mt: '0.5rem' }} />
            <Skeleton variant="rounded" height='40px' sx={{ mt: '0.5rem' }} />
        </>}

        {repliesByRsvp && <>
            {Object.entries(repliesByRsvp)
                .filter(([_, replies]) => replies.length > 0)
                .map(([rsvp, replies]) => <React.Fragment key={rsvp}>
                    <Typography color="primary" variant="h5" mt="1rem" mb="1rem">
                        {TRANSLATIONS[rsvp as Rsvp]} ({replies.length} Antworten, {replies.flatMap(r => r.people).filter(p => !p.isDeleted).length} Personen)
                    </Typography>

                    <Grid container spacing="0.5rem" alignItems="stretch">
                        {
                            replies.map(reply => <React.Fragment key={reply.id}>
                                {reply.people.map(person => <Grid item xs={12} md={6} lg={4} display="flex" key={person.id}>
                                    <GuestCard
                                        apiKey={apiKey}
                                        reply={reply}
                                        person={person}
                                        photoHints={photoHintsByPersonId[person.id!]}
                                        onHintPosted={() => getPhotoChallengeHints()}
                                    />
                                </Grid>)}
                            </React.Fragment>)
                        }
                    </Grid>
                </React.Fragment>)}
        </>}

        {missingReplies && <>
            <Typography color="primary" variant="h5" mt="1rem" mb="1rem">
                Fehlende Rückmeldungen ({missingReplies.length})
            </Typography>

            <ul>
                {missingReplies.map(guest => <li key={guest.id}>
                    {guest.firstName} {guest.lastName}
                </li>)}
            </ul>
        </>}

        {foodPreferences && <>
            <Typography color="primary" variant="h5" mt="1rem" mb="1rem">
                Essensauswahl
            </Typography>

            <ul>
                {Object.entries(foodPreferences).map(([meal, number]) => <li key={meal}>
                    <Icon component={FOOD_ICONS[meal as FoodPreference]}
                        sx={{ fontSize: '1rem', mr: '0.25rem' }} />
                    {TRANSLATIONS[meal as FoodPreference]}: {number}
                </li>)}
            </ul>
        </>}
    </Box>;
}